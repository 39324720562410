﻿require('jquery');
require('jquery-validation');

module.exports = {
    OnSuccess: function OnSuccess() {
        if ($('form').valid()) {
            $('form').submit();
        }
    },
    ReCaptchaValidation: function ReCaptchaValidation() {
        if (!grecaptcha) {
            var v = grecaptcha.getResponse();
            if (v.length == 0) {
                return false;
            }
            else {
                grecaptcha.execute();
                return true;
            }
        }
    }
};